import { Formik } from 'formik'
import { Form } from 'formik-antd'
import { Dispatch, SetStateAction } from 'react'
import * as yup from 'yup'
import {
  Button,
  DatePicker,
  ErrorMessage,
  Icon,
  Input,
  NumberInput,
  PortfolioBadge,
} from '@netpurpose/np-ui'
import { CreateablePortfolioType } from '@netpurpose/types'
import {
  BottomContainer,
  FieldLabelContainer,
  FileUploadedContainer,
  GridContainer,
  LabelText,
  ModalBodyContainer,
  RadioButton,
  RadioContainer,
  RadioContent,
  RadioGroup,
} from './CreatePortfolioAction.style'

export const CreatePortfolioForm = ({
  handleUpload,
  setStep,
  visible,
  fileName,
  isPending,
  errorMessage,
  setErrorMessage,
}: {
  handleUpload: (formValues: {
    name: string
    type: CreateablePortfolioType
    totalValue: number
    valuationDate: Date
  }) => void
  setStep: Dispatch<SetStateAction<1 | 2>>
  visible: boolean
  fileName: string | undefined
  isPending: boolean
  errorMessage: string | null
  setErrorMessage: Dispatch<SetStateAction<string | null>>
}) => (
  <Formik
    initialValues={{
      name: '',
      valuationDate: new Date(),
      // Casting to unknown because of a type mismatch between initial values and submitted values
      // totalValue and type cannot be undefined but we want them to be empty form fields initially
      totalValue: undefined as unknown as number,
      type: undefined as unknown as CreateablePortfolioType,
    }}
    validationSchema={yup.object({
      name: yup.string().required('Required'),
      type: yup.string().required('Required'),
      totalValue: yup.number().required('Required'),
      valuationDate: yup.date().required('Required'),
    })}
    onSubmit={handleUpload}
  >
    {({ setFieldValue }) => (
      <Form>
        <ModalBodyContainer $visible={visible} $morePadding>
          <FieldLabelContainer>
            <Form.Item name="name">
              <LabelText>Name</LabelText>
              <Input
                name="name"
                aria-label="Portfolio name"
                placeholder="Sustainable portfolio"
                suffix={<span />}
              />
            </Form.Item>
          </FieldLabelContainer>
          <FieldLabelContainer>
            <LabelText>File Uploaded</LabelText>
            <FileUploadedContainer>
              <Icon icon="Excel" alt="Excel icon" viewbox="0 0 33 32" width={24} height={24} />
              <span>{fileName}</span>
            </FileUploadedContainer>
          </FieldLabelContainer>
          <FieldLabelContainer>
            <Form.Item name="type">
              <LabelText>Type</LabelText>
              <RadioGroup>
                <RadioButton value="private" onClick={() => setFieldValue('type', 'private')}>
                  <RadioContainer className="radio-container">
                    <PortfolioBadge type="private" />
                    <RadioContent>
                      <span>Portfolio</span>
                      <span>A portfolio held by yourself or your firm.</span>
                    </RadioContent>
                  </RadioContainer>
                </RadioButton>
                <RadioButton value="benchmark" onClick={() => setFieldValue('type', 'benchmark')}>
                  <RadioContainer className="radio-container">
                    <PortfolioBadge type="benchmark" />
                    <RadioContent>
                      <span>Benchmark</span>
                      <span>A portfolio used for comparison.</span>
                    </RadioContent>
                  </RadioContainer>
                </RadioButton>
              </RadioGroup>
            </Form.Item>
          </FieldLabelContainer>
          <GridContainer>
            <LabelText>Currency</LabelText>
            <LabelText>Assets under management (AUM)</LabelText>
            <LabelText>Valuation date</LabelText>
            <div>
              <Input name="currency" aria-label="Currency" value={'$ (USD)'} disabled />
            </div>
            <Form.Item name="totalValue">
              <NumberInput
                name="totalValue"
                aria-label="Assets under management"
                placeholder="1,000,000"
                type="number"
                controls={false}
                min={0}
              />
            </Form.Item>
            <Form.Item name="valuationDate">
              <DatePicker
                name="valuationDate"
                aria-label="Valuation date"
                disabledDate={(current: Date) => {
                  return current > new Date()
                }}
                allowClear={false}
                format="DD/MM/YYYY"
              />
            </Form.Item>
          </GridContainer>
          {errorMessage && (
            <ErrorMessage error={`Portfolio could not be created: ${errorMessage}`} />
          )}
          <BottomContainer>
            <Button
              type="primary"
              onClick={() => {
                setStep(1)
                setErrorMessage(null)
              }}
            >
              Go back
            </Button>
            <Button
              level="tertiarySubmit"
              loading={isPending}
              disabled={isPending}
              data-testid="create-portfolio-form-submit-button"
            >
              Create portfolio
            </Button>
          </BottomContainer>
        </ModalBodyContainer>
      </Form>
    )}
  </Formik>
)
